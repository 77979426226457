<template>
    <section id="benchmarks">
      <card icon="compass"
            :headline="$t('visitors.benchmarks.headline')"
            :description="$t('visitors.benchmarks.description')"
      >

      </card>
    </section>
</template>

<script>
    export default {
        name: "Benchmarks"
    }
</script>

<style scoped lang="scss">

</style>
